import { makeStyles, Theme } from '@material-ui/core';

export const useCartButtonStyles = makeStyles((theme: Theme) => ({
  cartButton: {
    display: 'flex',
    borderRadius: 2,
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    padding: theme.spacing(1.5),

    '&:hover, &:focus-visible': {
      border: '1px solid transparent',
      backgroundColor: 'transparent',
      [theme.breakpoints.up('sm')]: {
        border: `1px solid`,
      },
    },
  },
  notificationMark: {
    display: 'inline-block',
    position: 'absolute',
    fontSize: 12,
    fontWeight: 600,
    width: 16,
    height: 16,
    lineHeight: '16px',
    borderRadius: '50%',
    top: -6,
    right: -6,
    scale: 1,
    visibility: 'visible',
    transition: 'scale 0.2s ease-in-out, visibility 0.2s ease-in-out',
  },
  notificationHidden: {
    display: 'inline-block',
    visibility: 'hidden',
    width: 0,
    scale: 0.5,
  },
  positionRelative: {
    display: 'block',
    position: 'relative',
  },
}));

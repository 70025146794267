import { Container } from '@finn/atoms';
import { Logo } from '@finn/design-system/icons/logo';
import { MinimisedFooterData } from '@finn/ui-cosmic';
import { cn } from '@finn/ui-utils';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import Link from 'next/link';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  footerLinks: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(4),
    gap: theme.spacing(2),
    '& > *': {
      ...theme.typography.c2,
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      order: 2,
      gap: theme.spacing(6),
      alignItems: 'center',
      '& > *': {
        ...theme.typography.c1,
      },
    },
  },
  copyrightLabel: {
    ...theme.typography.c3,
    marginTop: theme.spacing(2),
  },
}));

type MinimisedFooterProps = {
  data: MinimisedFooterData;
};

const MinimisedFooter = ({ data }: MinimisedFooterProps) => {
  const classes = useStyles();
  const i18n = useIntl();

  return (
    <footer className="bg-black pb-8 pt-12 text-white">
      <Container>
        <div className={classes.contentWrapper}>
          <div className={cn(classes.footerLinks, 'text-white')}>
            {(data?.metadata?.privacy || []).map((item) => (
              <Link
                key={item?.slug}
                href={item?.metadata?.href}
                target="_blank"
              >
                {item?.metadata?.label}
              </Link>
            ))}
          </div>
          <div>
            <Logo className="fill-white" />
            <Typography className={classes.copyrightLabel}>
              {i18n.formatMessage({ id: 'general.copyright' })}
            </Typography>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default MinimisedFooter;

import { ShoppingCartFilled } from '@finn/design-system/icons/shopping-cart-filled';
import { ModalContainer, ModalKey, useOpenModal } from '@finn/ua-modals';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { ButtonBase } from '@material-ui/core';
import cn from 'classnames';
import React, { useCallback, useEffect } from 'react';

import { EmptyCartModal } from '~/modules/checkout/components/CartModals/EmptyCartModal';
import { ReturnToCheckoutModal } from '~/modules/checkout/components/CartModals/ReturnToCheckoutModal';
import { useGetPreviousCart } from '~/modules/checkout/hooks/useGetPreviousCart';
import { useGetPreviousDeal } from '~/modules/checkout/hooks/useGetPreviousDeal';

import { useCartButtonStyles } from './cartButtonStyles';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

// thank you page shouldn't show cart button as user just finished the checkout
const hideCartOnPaths = ['thank_you'];

export const CartButton: React.FC = () => {
  const [shouldHide, setShouldHide] = React.useState(false);
  const classes = useCartButtonStyles();
  const openModal = useOpenModal();
  const { data } = useGetPreviousDeal();
  const previousCart = useGetPreviousCart();
  const track = useTrackingStore((state) => state.track);
  const previousDealId = data?.dealId;
  const hasCartOrDeal = previousCart?.isCart || previousDealId;
  const hasCartOrDealOrPDP =
    previousCart?.isCart || previousCart?.isPDP || previousDealId;

  const onButtonClick = useCallback(() => {
    if (hasCartOrDealOrPDP) {
      track(TrackingEventName.BASKET_CLICKED, {
        location: 'full-basket',
      });
      openModal(ModalKey.RETURN_TO_CHECKOUT);
    } else {
      track(TrackingEventName.BASKET_CLICKED, {
        location: 'full-basket',
      });
      openModal(ModalKey.EMPTY_CART);
    }
  }, [hasCartOrDealOrPDP, openModal]);

  useEffect(() => {
    const currentPath = window.location.pathname || '';
    const shouldHideOnCurrentPath = hideCartOnPaths.some((path) =>
      currentPath.includes(path)
    );
    setShouldHide(shouldHideOnCurrentPath);
  }, []);

  if (shouldHide) {
    return null;
  }

  return (
    <>
      <ButtonBase
        className={cn(
          classes.cartButton,
          'sm:hover:!bg-snow sm:hover:!border-pearl sm:focus-visible:!bg-snow sm:focus-visible:!border-pearl'
        )}
        onClick={onButtonClick}
      >
        <span className={classes.positionRelative}>
          <span
            className={cn({
              [cn(classes.notificationMark, 'bg-trustedBlue text-white')]:
                hasCartOrDeal,
              [classes.notificationHidden]: !hasCartOrDeal,
            })}
          >
            1
          </span>
          <ShoppingCartFilled />
        </span>
      </ButtonBase>
      <ModalContainer
        modalKey={ModalKey.EMPTY_CART}
        ModalComponent={EmptyCartModal}
      />
      <ModalContainer
        modalKey={ModalKey.RETURN_TO_CHECKOUT}
        ModalComponent={ReturnToCheckoutModal}
      />
    </>
  );
};
